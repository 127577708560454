<template>
  <b-card title="Lista de entregas" id="cardo">
    <div v-if="toExport.length > 0" id="export-button">
      <json-excel :data="toExport">
        <feather-icon
          icon="SaveIcon"
          size="40"
          class="ml-2 text-success pointer"
        />
      </json-excel>
    </div>
    <b-table
      v-if="searchedUsers"
      :items="searchedUsers"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <div @click="movementRetrieve(row.item)">

            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </div>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card class="">
          <b-row class="mb-2">
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Ultimo depósito : </strong> {{retrieveLastMovement(row,1)}}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Ultimo Saque : </strong>{{retrieveLastMovement(row,2)}}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Rollover a cumprir : </strong>R$ 129.000,00
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #cell(actions)="data">
        <b-link :href="'/client-single/'+ data.item.id" v-if="checkContains('details') == true">
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
        </b-link>
        <b-link :href="'/masters/client-single/'+ enc(data.item.id)" v-if="checkContains('master-details') == true">
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
        </b-link>
        <feather-icon
          v-if="checkContains('remove-relation') == true"
          icon="TrashIcon"
          class="mr-50 text-warning pointer"
          @click="removeRelation(relationId(data.item))"
        />
        <feather-icon
          v-if="checkContains('add-relation') == true"
          icon="PlusIcon"
          class="ml-2 text-success pointer"
          @click="addRelation(data.item)"
        />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{status[0][data.value]}}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import JsonExcel from "vue-json-excel";

import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
  },
  props:['searchedUsers', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {
    return {/*{key:'created_at', label: 'Registrado em', formatter:'dateFormatter'},  {key:'invitecode', label: 'Cód Convite',formatter: 'cellDecoupe'}, {key:'balance', label: 'Saldo atual', formatter: 'ballancer'}, { key: 'status', label: 'Status' }, */
      fields: ['id',{key:'user.username', label: 'Usuario'},{key:'fullname', label: 'Nome', formatter: 'getDocument'}, {key:'value_released', label: 'Valor entregado'}, {key:'created_at', label: 'fecha'}, {key:'transaction_id', label: 'Nro. Trans.'}, {key:'released_by', label: 'Responsable'}],
      status: [{
        "ACTIVATED": 'Ativado', "ACTIVED": 'Ativado', 2: 'Verificado', 3: 'Bloqueado', "PENDING_VALIDATION": 'AG. VALIDAÇÃO', 5: 'Applied',
      },
      {
        "ACTIVATED": 'light-primary', "ACTIVED": 'light-primary', 2: 'light-success', 3: 'light-danger', "PENDING_VALIDATION": 'light-warning', 5: 'light-info',
      }],
      users:null,
      queriedUsers: ['1', '9'],
      movements:null,
      userLastMovements: [],
      selectedUserLastWithDraw: [],
      selectedUserWalletId:null,
      wallets:[],
      toExport:[]
    }
  },
  apollo:{
    wallets:{
      query: gql `query($containsUsersId:[String!]){
        wallets(first:10000,containsUsersId:$containsUsersId){
          data{
            user_id,
            wallet_code,
            wallet_type_id,
            balance,
            id
          },
        }
      }`,
      client: 'walletClient',
      variables () {
        return {
          containsUsersId: this.queriedUsers,
        }
      }
    },
    movements:{
      query: gql `query($containsMovementTypes:[Int!], $walletId:String){
        movements(first:100,containsMovementTypes:$containsMovementTypes,
        wallet_id:$walletId){
          data{
            id,
            value,
            movement_type_id,
            wallet_id,
            created_at
          },
        }
      }`,
      client: 'walletClient',
      variables () {
        return {
          containsMovementTypes: [0,1],
          walletId: this.selectedUserWalletId,
        }
      }
    },
  },
  watch:{
    searchedUsers: function(){
      this.searchedUsers = this.searchedUsers.map(u => {
        u.user.fullname = u.user.documents.filter(d => d.type == "FULLNAME")[0]?.value;
        return u;
      });
      this.users = this.searchedUsers
      _.each(this.searchedUsers, this.formatSave);
    },
    users: function(){
      let obj = this.users.map(u => u.id)
      this.queriedUsers = obj
    },
    movements: function(){
      if(this.movements.data.length>0){
        if(_.findWhere(this.selectedUserLastDeposit, this.movements) === undefined) {
          this.userLastMovements.push(this.movements);
        }
      }
    }
  },
  methods:{
    formatSave(e){
/*      let toSave = {
        "id" : e.id,
        "phone": e.username,
        "status": e.status,
        "register": e.created_at,
        "invite_code": e.documents.filter(d => d.type == "INVITECODE")[0]?.value,
        "click_id": e.documents.filter(d => d.type == "CLICKID")[0]?.value,
        "birthdate": e.documents.filter(d => d.type == "BIRTHDATE")[0]?.value,
        "gender": e.documents.filter(d => d.type == "GENDER")[0]?.value,
        "nickname": e.documents.filter(d => d.type == "NICKNAME")[0]?.value,
        "fullname": e.documents.filter(d => d.type == "FULLNAME")[0]?.value,
        "email": e.documents.filter(d => d.type == "EMAIL")[0]?.value
      }
      this.toExport.push(toSave);*/
    },
    getDocument(value, key, item){
      let obj = item.user.documents.filter(d => d.type == key.toUpperCase())
      return obj.length > 0 ? obj[0].value : '-'
    },

    cellDecoupe(value, key, item){
      let obj = item.documents.filter(d => d.type == key.toUpperCase())
      return obj.length > 0 ? obj[0].value : '-'
    },
    ballancer(value, key, item){
      let obj = this.wallets.data.filter(w => w.user_id == item.id)
      return obj.length > 0 ? obj[0].balance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : '-'
    },
    movementRetrieve(userData){
      let wallet = this.wallets.data.filter(w => w.user_id == userData.id && w.wallet_type_id == 1)
      if(wallet.length > 0){
        this.selectedUserWalletId = wallet[0].id
      }else{
        this.selectedUserWalletId = null
      }
    },
    retrieveLastMovement(data,movementType){
      let realWallet = _.where(this.wallets.data, {user_id: data.item.id, wallet_type_id:1})
      if(this.userLastMovements.length > 0){
        let moves = _.pluck(this.userLastMovements, 'data');
        let movement = moves[0].filter(w => w.wallet_id == realWallet[0].id && w.movement_type_id == movementType).reverse()
        if(movement.length > 0)
          return this.$datter.convert((movement[0].created_at));
      }
      return 'Sem informação';
    },
    checkContains(index){
     return _.contains(this.contextActions,index); 
    },
    relationName(value, key, item){
      let typeVerifier = this.addInfo.data.filter(d => d.related_user.id == item.id)
      if(typeVerifier.length > 0){
        return typeVerifier[0].relation_type.code
      }else{
        return this.addInfo.data.filter(d => d.related_to_me.id == item.id)[0].relation_type.code
      }
    },
    removeRelation(relationId){
      this.$emit('removeRelation',relationId);
    },
    addRelation(user){
      this.$emit('addRelation',user);
    },
    relationId(item){
      let typeVerifier = this.addInfo.data.filter(d => d.related_user.id == item.id)
      if(typeVerifier.length > 0){
        return typeVerifier[0].id
      }else{
        return this.addInfo.data.filter(d => d.related_to_me.id == item.id)[0].id
      }
    },
    dateFormatter(value){
      return this.$datter.convert(value);
    },
    enc(id){
      return btoa(id);
    }
  },
  created(){
    if(this.addInfo != null && this.addInfo.key == 'related'){
      this.fields = this.fields.slice(0,-1)
      this.fields.push({key:'relation_name', label:'Relação', formatter:'relationName'})
      this.fields.push({ key: 'actions', label: 'Ações' })
    }
    if(this.customFields != null){
      this.fields = this.customFields;
    }
    _.each(this.searchedUsers, this.formatSave);
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

</style>